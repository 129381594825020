import { useRequest } from '@/composables/useRequest';
import { ApiMethod } from '@/enums/api.enum';
import type { TagsRequest } from '@/types/tags/tags-request.type';
import type { Tag, TagsResponse } from '@/types/tags/tags-response.type';
import { camelToSnake, generateHeader } from '@/utils/api.util';

// https://wiki.smilegate.net/pages/viewpage.action?pageId=427850059 --> DELETED
// https://wiki.smilegate.net/pages/viewpage.action?pageId=450853136 --> NEW
export const fetchTagsApi = async (params: TagsRequest): Promise<Tag[] | undefined> => {
  const { data } = await useRequest<TagsResponse>('studio/v1/tags/search', {
    headers: { ...generateHeader('X-Lang', 'X-Nation') },
    method: ApiMethod.Get,
    params: camelToSnake(params)
  });

  return data?.tags;
};
